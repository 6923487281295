export function openNav() {
  $("#hamburger .open").addClass("hidden");
  $("#hamburger .close").removeClass("hidden");
  $("#main-navigation").removeClass("hidden");
}

export function closeNav() {
  $("#hamburger .close").addClass("hidden");
  $("#hamburger .open").removeClass("hidden");
  $("#main-navigation").addClass("hidden");
}

export function openSearch() {
  $("#search-toggle .search-open").addClass("hidden");
  $("#search-toggle .search-close").removeClass("hidden");
  $("#search-bar").removeClass("hidden");
  $("#search-bar input").focus();
}

export function closeSearch() {
  $("#search-toggle .search-close").addClass("hidden");
  $("#search-toggle .search-open").removeClass("hidden");
  $("#search-bar").addClass("hidden");
}
