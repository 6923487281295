import {
  openNav,
  closeNav,
  openSearch,
  closeSearch,
} from "./modules/navigation.js";

// Stop transitions before page has loaded
$(window).on("load", function () {
  $("body").removeClass("preload");
});

// Make focus events fire a click, but not vice versa
$("#hamburger, #search-toggle, .credit").mousedown(function () {
  $(this).data("mouseDown", true);
});

$("#hamburger, #search-toggle, .credit").mouseup(function () {
  $(this).removeData("mouseDown");
});

$("#hamburger, #search-toggle, .credit").focus(function () {
  if (!$(this).data("mouseDown")) {
    $(this).click();
  }
});

// Toggle navigation
$("#hamburger").click(function (e) {
  e.preventDefault();
  $($("#main-navigation").hasClass("hidden") ? openNav() : closeNav());
});

// Toggle search
$("#search-toggle").click(function (e) {
  e.preventDefault();
  $($("#search-bar").hasClass("hidden") ? openSearch() : closeSearch());
});

// Reset if browser is resized
$(window).resize(function () {
  if ($(window).width() >= 1280) {
    closeNav();
  }
});

// Accordion
$(".accordion").click(function (e) {
  $(this).next("div").slideToggle();
  $(this).find("svg").toggleClass("rotate-180");
});

// Lightbox
$(".open-lightbox").click(function () {
  $(this).next(".lightbox").removeClass("hidden");
});

$(".close-lightbox, .lightbox").click(function () {
  $(".lightbox").addClass("hidden");
});

// Credit  links
$(document).on("click", ".credit", function () {
  $(this).next().toggleClass("hidden block");
});

// Hero carousel
const swiper = new Swiper(".swiper", {
  a11y: true,
  loop: true,
  threshold: 3,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    bulletActiveClass: "swiper-pagination-bullet-active",
    bulletClass: "swiper-pagination-bullet border border-black bg-black",
  },
});

// BTT button
$(".btt-btn").click(function () {
  window.scrollTo(0, 0);
});
